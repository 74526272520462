import * as React from 'react';
import {
  Field,
  FieldArray,
  formValueSelector,
  getFormSyncErrors,
  InjectedFormProps,
  reduxForm,
  submit,
} from 'redux-form';
import { difference, flow, forEach, get, head, intersection, isEmpty, map, size } from 'lodash';
import { II18nMessages } from '../../../../services/branding/models';
import { Button, Col, ControlLabel, Form, FormGroup, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import FieldFormControl from '../../../../components/FieldFormControl';
import SelectControl from '../../../../components/SelectControl';
import Destination from './components/Destination';
import { connect } from 'react-redux';
import * as Commonmark from 'react-commonmark';
import AlternativeDatePicker from '../../../../components/AlternativeDatePicker/index';
import {
  IChannel,
  IDestination,
  IIndustry,
  IOccupation,
  IOptionMap,
  IPolicyApplication, IPremium,
  IProduct,
  IStateCities,
  TripType,
} from '../../../../data/api/models';
import {
  annualLimit,
  isFutureDate,
  isRequired,
  isValidDate,
  minTripDuration,
  singleLimit,
  singleMaxTripDuration,
} from '../../../../util/validators';
import * as moment from 'moment';
import { showDestinations, showRegions, showTripExtension } from '../../../../data/api/api';
import { actions as quoteActions, getInitValues } from '../../../../services/quote/reducer';
import IconTravel from './icon/IconTravel';
import { IAuthState } from '../../../../services/auth/models';
import { bindActionCreators } from 'redux';
import { IQuoteActions } from '../../../../services/quote/models';
import { FaQuestionCircle } from 'react-icons/fa';
import confirm from 'react-confirm2';
import { DynamicTextWithHelp } from '../AdditionalCover/components/QuestionOptions/DynamicTextWithHelp';
import { REGION_WORLDWIDE_EXCLUDING_LABEL_OLD } from '../../index';
import BusinessContinuityPlan from '../../../../helpers/BusinessContinuityPlan';
import StateCities from './components/StateCities';
import { reorderObjectKeyValues } from '../../../../helpers/Objects';
import { RemoveUkRegion } from '../../../../helpers/RemoveUkRegion';
import { IStore } from '../../../../redux/IStore';

interface ITripDetailsFormProps extends InjectedFormProps {
  destinations: IDestination[];
  stateCities: IStateCities[];
  occupations: IOccupation[];
  industries: IIndustry[];
  auth: IAuthState;
  i18n: II18nMessages;
  groupType: string;
  tripType: TripType;
  startDate: string;
  region: string;
  maxTripDuration: string;
  quoteType: string;
  endDate: string;
  dispatch: any;
  options: IOptionMap;
  product: IProduct;
  destinationsValue: string[];
  stateCitiesValue: string[];
  occupationValue: string[];
  industryValue: string[];
  channel: IChannel;
  quote: IPolicyApplication;

  renderDefinition(name: string, useProvidedString?: boolean): any;

  actions: {
    submit(name: string);
    quote: IQuoteActions;
  };
  schemeId: string;
  createdBeforeFixedSchemeVersionRule?: boolean;
  synchronousError: any;
  quoteForEdit: boolean;
  premiums?: IPremium[]
}

class TripDetailsForm extends React.Component<ITripDetailsFormProps, any> {
  private startDatePicker: any;
  private endDatePicker: any;

  constructor(props) {
    super(props);
    this.startDatePicker = React.createRef();
    this.endDatePicker = React.createRef();
  }

  public componentWillMount() {
    const { product, change, initialValues, quote } = this.props;

    const data = getInitValues(product);

    forEach(data, (value, key) => {
      if (initialValues[key] === null) {
        change(key, value);
      }
    });

    change('quotationTypes', get(quote, 'application.quoteType', '') === 'renewal' ? 'Admiral Direct Renewal' : 'Admiral Direct');

    // if (quote.application.quoteType) {
    //   dispatch(
    //     change('sales_channel', 'admiral-b2c-renewal'),
    //   );
    // }

    change('residency', 'uk');
  }

  public componentDidUpdate(previousProps) {
    const { tripType, change, destinationsValue, startDate, endDate } = this.props;
    if (tripType === 'single') {
      const start = moment(startDate, 'DD/MM/YYYY');
      const end = moment(endDate, 'DD/MM/YYYY');
      change('region', '');
      change('maxTripDuration', end.diff(start, 'days'));
      change('renewalStatus', null);
    }

    if (previousProps.tripType === 'single' && tripType === 'annual') {
      change('destinations', '');
      change('stateCities', ['']);
      change('endDate', '');
      change('maxTripDuration', 31);
    }

    if (destinationsValue.length && !intersection(destinationsValue, ['USA', 'United States']).length) {
      change('stateCities', ['']);
    }
  }

  public componentWillReceiveProps(nextProps) {
    const { tripType, dispatch, change, actions, schemeId } = this.props;
    if (schemeId && tripType !== nextProps.tripType) {
      dispatch(change('schemeId', ''));
      actions.quote.calculate(null, true);
    }
  }

  private renderOption = (option, key) => {
    const { options, product } = this.props;

    if (!['cruise_cover', 'winter_sports'].includes(key)) {
      return;
    }

    const popOverText = product.metadata.definitions[`${key}_alt_help`];
    const dynamicText = product.metadata.definitions[`${key}_alt_dynamic`];
    const selectedNoText = product.metadata.definitions[`${key}_alt_selected_no`];
    let title = product.metadata.definitions[`${key}_alt_title`];

    if (key === 'winter_sports') {
      title = title.replace('{alt_value}', 'Up to £500')
    }

    const popOverTitle = `**${option.question}**\n\n`;

    return (
      <DynamicTextWithHelp popOverText={popOverText} title={title}
                           dynamicText={dynamicText}
                           selectedNoText={selectedNoText}
                           name={`options.${key}`} key={key}
                           props={this.props}
                           optionKeyName={key}
                           validationMessage={'single'}
                           active={options && options[`${key}`]} popOverTitle={popOverTitle}/>
    );
  }

  private onClickEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  private isRenewal() {
    return this.props.quote.application.quoteType === 'renewal';
  }

  private tripTypeButtons() {
    if (this.isRenewal()) {
      return null;
    }

    const { product } = this.props;

    return map(product.metadata.trip_type, (name, key) => {
      return (
        <Col xs={6} key={key} className="trip-type-btn-container">
          <Field
            name="tripType"
            id={key + '-btn'}
            type="radio"
            value={key}
            component={FieldFormControl}
            validate={[isRequired]}
          >
            <div className={`trip-type-btn ${key}`}>
              <IconTravel type={key}/>
              <p>{name}</p>
            </div>
          </Field>
        </Col>
      );
    });
  }

  private handleSubmit = () => {
    const { actions, quoteType } = this.props;

    if (quoteType !== 'renewal') {
      this.checkForErrorsBeforeSubmit();
      return;
    }

    confirm('Warning', {
      confirmLabel: 'Next',
      description: (
        <span>
          Clicking next will save the details entered.
        </span>
      ),
      done: () => {
        this.checkForErrorsBeforeSubmit();
        return;
      },
      close: () => {
        return;
      },
    });

  }

  private checkForErrorsBeforeSubmit = () => {
    const { actions, synchronousError } = this.props;
    Promise.resolve(actions.submit('quote')).then(() => {
      if (synchronousError && !isEmpty(synchronousError)) {
        window.scrollTo(0, 0);
      }
    });
  }

  public render() {
    const {
      // groupType,
      // occupations,
      // industries,
      // endDate,
      // occupationValue,
      // quote,
      // maxTripDuration,
      // industryValue,
      // change,
      channel,
      i18n,
      submitting,
      tripType,
      handleSubmit,
      destinations,
      stateCities,
      valid,
      startDate,
      product,
      destinationsValue,
      stateCitiesValue,
      renderDefinition,
      submitFailed,
      createdBeforeFixedSchemeVersionRule,
      quoteForEdit,
      premiums
    } = this.props;

    const annualTripDuration = map(get(product, 'metadata.annual_trip_durations', {}), (name, key) => {
      return {
        label: name,
        value: parseInt(key, 10),
      };
    });

    const hideUKRegion = get(head(premiums), 'information.hideUkRegion', false)
    const productRegions = RemoveUkRegion(product.metadata.regions, quoteForEdit, hideUKRegion)
    const hasUKRegion = get(productRegions, 'uk', null) !== null;

    const regionsList = map(productRegions, (name, key) => {
      if (key === 'worldwide_excluding' && createdBeforeFixedSchemeVersionRule) {
        return {
          label: REGION_WORLDWIDE_EXCLUDING_LABEL_OLD,
          value: key,
        };
      }

      return {
        label: name,
        value: key,
      };
    });

    /*let durationsList = [];

    if (product.metadata.annual_trip_durations) {
      durationsList = map(product.metadata.annual_trip_durations, (name, key) => ({
        label: name,
        value: key,
      }));
    }*/

    const popoverClickRootClose = (
      <Popover id="popover-trigger-click-root-close" title="Regions">
        {hasUKRegion && <Commonmark source={product.metadata.definitions.region_uk}/>}
        <Commonmark source={product.metadata.definitions.region_europe}/>
        <Commonmark source={
          createdBeforeFixedSchemeVersionRule
            ? product.metadata.definitions.region_worldwide_excluding_old
            : product.metadata.definitions.region_worldwide_excluding
        }
        />
        <Commonmark source={
          createdBeforeFixedSchemeVersionRule
            ? product.metadata.definitions.region_worldwide_old
            : product.metadata.definitions.region_worldwide
        }
        />
      </Popover>
    );

    const popoverClickRootCloseTripExtension = (
      <Popover id="popover-trigger-click-root-close-trip-extension" title="TripExtension">
        <Commonmark
          source={'Annual Multi-Trip policies cover multiple trips of up to 31 days per trip as standard. You can increase the maximum trip duration for the policy term, either up to 45 days or up to 60 days per trip.'}/>
      </Popover>
    );

    const bcp = channel.bcp || null;
    let isTripTypeAllowed = true;
    if (tripType === 'single') {
      isTripTypeAllowed = BusinessContinuityPlan.isSchemeTypeAllowed('single', bcp);
    }
    if (tripType === 'annual') {
      isTripTypeAllowed = BusinessContinuityPlan.isSchemeTypeAllowed('annual', bcp) || this.isRenewal();
    }

    const tripGroupType = reorderObjectKeyValues(product.metadata[`group_type_trip_${tripType}`], ['individual', 'couple', 'family', 'family_one_adult', 'group']);

    return (
      <Form horizontal={true} onSubmit={handleSubmit} autoComplete="off">
        {isRequired && submitFailed && !valid && (
          <div className="error-block-container">
            <h1>Whoops!</h1>
            <p>Missing something? Please check the areas marked in orange before you continue.</p>
          </div>
        )}
        <div className="steps-container">

          {/* Type Type */}

          {size(product.metadata.trip_type) > 1 && (
            <Row className="trip-type-container">
              <h1>
                {tripType &&
                  <div>
                    You have chosen <span
                    className="trip-type-text">{tripType === 'single' ? 'Single Trip ' : 'Annual Multi-Trip '}
                </span>
                    Cover {renderDefinition('trip_type')}
                  </div>
                }

                {!tripType &&
                  <div>
                    What type of cover do you require?
                  </div>
                }
              </h1>
              <h4>

                {tripType === 'annual' &&
                  <div>
                    Annual Multi-Trip policies cover multiple short-duration trips of up to 31 days throughout the year
                  </div>
                }

                {tripType === 'single' &&
                  <div>
                    You can change to Annual Multi-Trip
                  </div>
                }

              </h4>

              {this.tripTypeButtons()}
            </Row>
          )}

          {(tripType && !isTripTypeAllowed) && (
            <div className="steps-container">
              <div className={'section'}>
                <div className={'important'}>
                  Sorry, we are not offering Annual Multi-trip policies at this time.
                </div>
              </div>
            </div>
          )}

          {tripType && isTripTypeAllowed &&
            <div>
              <div className="section-title">
                <h2 style={{ marginBottom: '20px' }}>About your trip</h2>
              </div>

              <div className="section-group">

                {showDestinations(tripType) && (
                  <div>
                    <FieldArray
                      name="destinations"
                      i18n={i18n}
                      destinations={destinations}
                      component={Destination}
                      destinationsValue={destinationsValue}
                      renderDefinition={renderDefinition}
                    />
                    <FieldArray
                      name="stateCities"
                      i18n={i18n}
                      stateCities={stateCities}
                      component={StateCities}
                      stateCitiesValue={stateCitiesValue}
                      destinationsValue={destinationsValue}
                      renderDefinition={renderDefinition}
                    />
                  </div>
                )}

                {showRegions(tripType) && (
                  <div className="section">
                    {i18n.quote.tripDetails.destinations} {renderDefinition('region')}
                    <Row className="margin-top">
                      <Col sm={12}>
                        <FormGroup controlId="destination">
                          <Col componentClass={ControlLabel} sm={3}>
                            {i18n.quote.tripDetails.region}{' '}
                            <OverlayTrigger
                              trigger="click"
                              rootClose={true}
                              placement="right"
                              overlay={popoverClickRootClose}
                            ><FaQuestionCircle className="info-btn"/>
                            </OverlayTrigger>
                          </Col>
                          <Col sm={9} className="destinations-inputs">
                            <Field
                              name="region"
                              id="destinations-inputs"
                              type="text"
                              component={SelectControl}
                              forceFilter={false}
                              options={regionsList}
                              validate={[
                                isRequired,
                              ]}
                            /></Col>
                        </FormGroup>
                        <div className="info-note">
                          {i18n.quote.tripDetails.europeanIncluded}{' '}
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}

                {/* Trip Dates */}

                <div className="section">
                  <FormGroup controlId="start-date" className="start-date">
                    <Col componentClass={ControlLabel} xs={5} onClick={this.onClickEvent}>
                      {showDestinations(tripType) ? 'Departure date ' : 'Start Date '}
                      {showDestinations(tripType) ? renderDefinition('travel_dates') :
                        renderDefinition('travel_dates_annual')}
                    </Col>
                    <Col xs={7} className="date-picker" onClick={this.onClickEvent}>
                      <div className="alternative-date-picker">
                        <div className="field start-date-input">
                          <Field
                            name="startDate"
                            type="masked-date"
                            inputmode="numeric"
                            placeholder="dd/mm/yyyy"
                            component={FieldFormControl}
                            disabled={this.isRenewal()}
                          />
                        </div>
                        <div className="icon" ref={this.startDatePicker}>
                          <Field
                            name="startDate"
                            minDate={moment()}
                            maxDate={showRegions(tripType) ?
                              moment().add('180', 'days') :
                              moment().add('365', 'days')}
                            component={AlternativeDatePicker}
                            validate={showRegions(tripType) ? [
                              isRequired,
                              isFutureDate,
                              isValidDate,
                              annualLimit,
                            ] : [
                              isRequired,
                              isFutureDate,
                              isValidDate,
                              singleLimit,
                            ]}
                            disabled={this.isRenewal()}
                          />
                        </div>
                      </div>
                      {/*<div className="alternative-date-picker">
                    <Field
                      name="dd"
                      placeholder="DD"
                      component={FieldFormControl}
                      validate={[isRequired]}
                    />
                    <Field
                      name="mm"
                      placeholder="MM"
                      component={FieldFormControl}
                      validate={[isRequired]}
                    />
                    <Field
                      name="yyyy"
                      placeholder="YYYY"
                      component={FieldFormControl}
                      validate={[isRequired]}
                    />
                    <Field
                      name="startDate"
                      component={AlternativeDatePicker}
                      validate={[
                        isRequired,
                        isFutureDate,
                      ]}
                    />
                  </div>*/}
                    </Col>
                  </FormGroup>
                </div>

                {tripType === 'annual' && (
                  <div className={'info-box'}>
                    <div>
                      <strong>IMPORTANT</strong> - {i18n.quote.tripDetails.coverWarning}
                    </div>
                  </div>
                )}

                {tripType === 'single' && (
                  <div className="section">
                    <FormGroup controlId="end-date" className="end-date">
                      <Col componentClass={ControlLabel} xs={5} onClick={this.onClickEvent}>
                        Return date {renderDefinition('travel_dates')}
                      </Col>
                      <Col xs={7} className="date-picker" onClick={this.onClickEvent}>
                        <div className="alternative-date-picker">
                          <div className="field end-date-input">
                            <Field
                              name="endDate"
                              type="masked-date"
                              inputmode="numeric"
                              placeholder="dd/mm/yyyy"
                              component={FieldFormControl}
                            />
                          </div>
                          <div className="icon">
                            <Field
                              name="endDate"
                              minDate={startDate ? moment(startDate, 'DD/MM/YYYY').add('2', 'day') : moment()}
                              maxDate={
                                moment(startDate, 'DD/MM/YYYY') < moment().add('91', 'days') ?
                                  moment().add('365', 'days') : moment().add('455', 'days')}
                              component={AlternativeDatePicker}
                              validate={[
                                isRequired,
                                isFutureDate,
                                isValidDate,
                                minTripDuration(startDate),
                                singleMaxTripDuration(startDate),
                              ]}
                            />
                          </div>
                        </div>
                      </Col>
                    </FormGroup>
                  </div>
                )}

                {showTripExtension(tripType) && (
                  <div className="section">
                    <Row className="margin-top">
                      <Col sm={12}>
                        <FormGroup controlId="tripExtension">
                          <Col componentClass={ControlLabel} sm={5}>
                            {'Individual Trip length'}
                            <OverlayTrigger
                              trigger="click"
                              rootClose={true}
                              placement="right"
                              overlay={popoverClickRootCloseTripExtension}
                            ><FaQuestionCircle className="info-btn"/>
                            </OverlayTrigger>
                          </Col>
                          <Col sm={7} className="destinations-inputs">
                            <Field
                              name="maxTripDuration"
                              id="trip-duration-inputs"
                              type="text"
                              component={SelectControl}
                              forceFilter={false}
                              options={annualTripDuration}
                              validate={[
                                isRequired,
                              ]}
                            /></Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                )}

                <div className="section">
                  {i18n.quote.tripDetails.travellers}
                  <div className="radio-group margin-top">
                    <Row>
                      {map(tripGroupType, (name, key) => {
                        return (
                          <>
                            <Col xs={6} className="full-width-xs" key={key} style={{ padding: '0px 15px' }}>
                              <div>
                                <Field
                                  name="groupType"
                                  type="radio"
                                  value={key}
                                  component={FieldFormControl}
                                  validate={[isRequired]}
                                >
                                  <div/>
                                  <span style={{ position: 'absolute' }} className={'radio-button-text'}>
                                  {name} {' '}
                                    {renderDefinition(`groupTypeDescriptions${key}`,
                                      i18n.quote.tripDetails.groupTypeDescriptions[key])}
                                </span>
                                </Field>
                              </div>
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                  </div>
                </div>
              </div>

              <div className="medical-section-group">
                {map(product.metadata.options, this.renderOption)}
              </div>

              <div className="btn-bar">
                <Button
                  bsStyle="primary"
                  id="continue"
                  type="button"
                  onClick={this.handleSubmit}
                  bsSize="lg"
                  className="pull-right"
                  disabled={submitting || (submitFailed && !valid)}
                >
                  CONTINUE
                </Button>
              </div>
            </div>
          }
        </div>
      </Form>
    );
  }
}

let selector;
let previousValues;

export default flow([
  reduxForm({
    form: 'quote',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onChange: (
      values: IPolicyApplication,
      dispatch,
      props: ITripDetailsFormProps,
    ) => {
      if (!previousValues) {
        previousValues = values;
        return;
      }

      let recalculateScore = false;

      if (difference(values.destinations, previousValues.destinations).length > 0) {
        recalculateScore = true;
      }

      if (values.region !== previousValues.region) {
        recalculateScore = true;
      }

      if (recalculateScore) {
        props.actions.quote.recalculateScreening();
      }

      previousValues = values;
    },
  }),
  (component) => {
    selector = formValueSelector('quote');

    return component;
  },
  connect(
    (state: IStore) => {
      const tripType = selector(state, 'tripType');
      const groupType = selector(state, 'groupType');
      const startDate = selector(state, 'startDate');
      const endDate = selector(state, 'endDate');
      const destinationsValue = selector(state, 'destinations');
      const stateCitiesValue = selector(state, 'stateCities');
      const occupationValue = selector(state, 'occupations');
      const industryValue = selector(state, 'industries');
      const region = selector(state, 'region');
      const maxTripDuration = selector(state, 'maxTripDuration');
      const residency = selector(state, 'residency');
      const quotationTypes = selector(state, 'quotationTypes');
      const schemeId = selector(state, 'schemeId');
      const quoteType = selector(state, 'quoteType');
      const options = selector(state, 'options');
      const premiums = selector(state, 'premiums');
      const synchronousError = getFormSyncErrors('quote')(state);
      // const dd = selector(state, 'dd');
      // const mm = selector(state, 'mm');
      // const yyyy = selector(state, 'yyyy');

      return {
        tripType,
        quotationTypes,
        residency,
        region,
        maxTripDuration,
        groupType,
        startDate,
        schemeId,
        quoteType,
        endDate,
        destinationsValue,
        stateCitiesValue,
        occupationValue,
        industryValue,
        options,
        synchronousError,
        premiums,

        // dd,
        // mm,
        // yyyy,
        auth: state.auth,
        createdBeforeFixedSchemeVersionRule: state.quote.application.createdBeforeFixedSchemeVersionRule,
        quoteForEdit: state.quote.quoteForEdit || false
      };
    },
    (dispatch) => ({
      actions: {
        submit: bindActionCreators(submit, dispatch),
        quote: bindActionCreators({ ...quoteActions }, dispatch),
      },
    }),
  ),
])(TripDetailsForm);
