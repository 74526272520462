export const RemoveUkRegion = (regions: any, isQuoteEdit: boolean, hideUkRegion: boolean) => {

  if (!isQuoteEdit && regions?.uk) {
    delete regions.uk
  }

  if (isQuoteEdit && hideUkRegion && regions?.uk) {
    delete regions.uk
  }

  return regions

}
